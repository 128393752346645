// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconBuildingStore, IconBuildingFortress } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconBuildingStore: IconBuildingStore,
    IconBuildingFortress: IconBuildingFortress
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: 'Practice',
    type: 'group',
    children: [
        {
            id: 'util-strategies',
            title: 'Problems',
            type: 'item',
            url: '/utils/problems',
            icon: icons['IconBuildingStore'],
            breadcrumbs: false
        }
    ]
};
