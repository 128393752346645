const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    API_SERVER: 'https://mockcode.uc.r.appspot.com/',
    // API_SERVER:  'http://localhost:5000/',
    // API_SERVER: 'https://backend-dot-zinc-shard-338321.ue.r.appspot.com/',
    MARKETPLACE_API: "https://bots.surmount.ai/"
};

export default config;
